.sidebar{
    flex: 1;
    display: flex;
    border-right: 0.5px solid rgb(233, 231, 231);
    min-height: 100vh;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;


    .top{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo{
            font-size: 18px;
            font-weight: bold;
            color: rgb(15, 31, 175);
        }

    }
    hr{
        height: 0;
        border: 0.5px solid rgb(233, 231, 231);
    }

    .center{
        margin: 10px;

        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            
            .title{
                font-size: 10px;
                color: #999;
                margin-top: 15px;
                margin-bottom: 5px;
            }

            li{
                display: flex;
                align-items: center;
                margin: 5px;
                cursor: pointer;

                &:hover{
                    background-color: #ece8ff;
                }

              
            }

           

            .icon{
                font-size:18px;
                color: rgb(15, 31, 175);
            }
            span{
                font-size: 12px;
                font-weight: 600;
                color:#888;
                margin-left: 10px;

            }
        }

        .background{
            margin-top: 10px;
            margin-bottom: 10px;
           
        }
    }

    .bottom{
        display: flex;
        align-items: center;
        margin: 10px;

        .colorOption{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #7451f8;
            cursor: pointer;
            margin: 4px;

            &:nth-child(1){
                background-color: #fff;
            }
            &:nth-child(2){
                background-color: #000;
            }
            
        }
    }

    .bottom-sidebar{
        display: flex;
        align-items: center;
        margin-left: 10px;
        margin-top: 20px;

        .logout-icon{
            margin-right: 5px;
            // margin-bottom: 10px;
            font-size: 22px;
            color: red;
        }
        span{
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
            color:#888;

        }
    }

}