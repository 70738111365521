.new{
    display: flex;

    .newContainer{
        flex:6;

        .top, .bottom{
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0,0,0.47); 
            box-shadow: 2px 4px 10px 1px  rgba(201, 201,201,0.47);
            padding: 20px;
            margin: 20px;
            justify-content: space-between;

            .topLeft{
                h1{
                    font-size: 18px;
                    color: lightgray;
                }
            }

            .topRight{
                font-size: 18px;
                color: lightgray;
            }

          
          

            .left{
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;

               .imgs{
                    width: 150px;
                    height: 150px;
                    // border-radius: 50%;
                }

                // .camerawrap{
                    // display: flex;
                    // width: 180px;
                    // height: 180px;
                    // // // border-radius: 50%;
                    // // background-color: #e0e0e0;
                    // align-items: center;
                    // justify-content: center;

                //    .imgs{
                //         // width: 100px;
                //         // height: 100px;
                //     }

                // }

             
            }

            .right{
                flex: 2;
                
                form{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 30px;
                    justify-content: space-between;
                    padding: 20px;

                    .formInput{
                        width: 40%;

                        label{
                            display: flex;
                            align-items: center;
                            gap: 20px;

                            .icon{
                                cursor: pointer;
                            }
                        }

                        input{
                            width: 100%;
                            border: none;
                            border-bottom: 1px solid rgb(184, 181, 181);
                            font-size: 16px;


                            &:focus{
                                outline: none;
                            }
                        }

                       
                    }
                    button{
                        width: 500px;
                        border: none;
                        background-color: teal;
                        color: white;
                        font-size: 10px;
                        // display:flex;
                        // flex-direction: row;
                        // align-items: center;
                        // justify-content: center;
                        
                        &:disabled{
                            background-color: rgb(69, 100, 69);
                            cursor: not-allowed;
                        }
                    }
                }


            }
        }
    }
}

.spinner-container{
    width: 10px important;
    height: 10px important;
  

    @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
     }
      .loading-spinner {
        width: 5px important;
        height: 5px important;
        // border: 1px solid #57f171; /* Light grey */
        // border-top: 10px solid #35c955; /* Black */
        border-radius: 50%;
        animation: spinner 1.5s linear infinite;
      }

}