.featured{
    flex:2;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0,0,0.47); 
    box-shadow: 2px 4px 10px 1px  rgba(201, 201,201,0.47);
    padding: 10px;

    .top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgb(102, 100, 100);

        .title{
            font-size: 18px;
        }
    }
    .bottom{
        display: flex;
        padding: 20px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;

        .featuredChart{
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 200px;
            height: 100px;
        }
        .title{
            font-weight:500;
            color: gray;

        }
        .amount{
            font-size: 30px;
        }
        .description{
            font-weight: 300;
            font-size: 12px;
            color: gray;
            text-align: center;
        }
        .summary{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .item{
                text-align: center;
                
                .itemTitle{
                    font-size: 14px;
                    color: gray;
                }
                .itemResult{
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    font-size: 14px;

                    &.positive{
                        color:green;
                    }
                    &.negative{
                        color: red;
                    }
                }
            }
        }
    }
}