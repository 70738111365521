.single{
    display: flex;
    width: 100%;

    .singleContainer{
        flex: 6;
        

        .top{
            display: flex;
            padding:20px;
            gap:20px;

            .left{
                flex: 1;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0,0,0.47); 
                box-shadow: 2px 4px 10px 1px  rgba(201, 201,201,0.47);
                padding: 10px;
                position: relative;

                .title{
                    font-size: 18px;
                    color: gray;
                }

                .editbutton{
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: #925592;
                    background-color: #f1eef7;
                    padding: 3px 5px;
                    cursor: pointer;
                    border-radius: 3px;
                    margin: 5px 5px 0px 0px;
                }

                .item{
                    display: flex;
                    // gap: 20px;
                    // padding: 20px;

                    .itemimg{
                        // width: 100px;
                        // height: 100px;
                        border-radius: 50%;
                        // object-fit: contain;
                        border:1px solid #999999;
                    }

                    .details{
                        .itemTitle{
                            margin-bottom: 10px;
                            color: #555555;
                        }
                        .detailItem{
                            margin-bottom: 10px;
                            font-size: 14px; 
                            
                            .itemKey{
                                font-weight: bold;
                                margin-right: 10px;
                                color: gray;
                            }

                            .itemValue{
                                font-weight: 300;

                            }
                        }


                    }
                }

             
            }

            .right{
                flex: 2;
             
            }
        }

        .bottom{
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0,0,0.47); 
            box-shadow: 2px 4px 10px 1px  rgba(201, 201,201,0.47);
            margin: 20px;
            padding: 20px;

            .listTitle{
                font-weight:500;
                color: gray;
                margin-bottom: 15px;
            }
        }
    }
}