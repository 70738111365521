.forgotPwd{
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    legend{
        font-size: 22px;
        color: green;
        margin: 0 10px;
    }

    form{
        display: flex;
        flex-direction: column;
        align-items: center;
        width:500px;
        height: 200px;
        align-items: center;
        justify-content: center;

        input{
            width: 200px;
            height: 30px;
            margin: 20px;
            font-size: 16px;
            padding: 10px;

            &:active{
                border: none;
                border-color: red;
            }
        }

        button{
            width: 220px;
            height: 40px;
            margin: 10px;
            padding: 10px;
            color: white;
            background-color: green;
            border: none;
            cursor: pointer;
            font-size: 14px;
        }

        span {
            color: red;
        }

        .text{
            font-size: 16px;
            color: gray;
            align-items: center;
            justify-content: center;
            width: 350px;
            justify-content: center;
            align-items: center;
        }
    }

}