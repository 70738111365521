.list{
    display:flex;
 
    .listContainer{
        flex: 6;
        margin:20px;

        .reviewTitle{
            flex: 1;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0,0,0.47); 
            box-shadow: 2px 4px 10px 1px  rgba(201, 201,201,0.47);
            padding: 20px;
            margin: 20px;


            .title{
                font-size: 18px;
                color: gray;
            }
        }
        .reviewBody{
            display:flex;
            padding: 10px;

            .reviewSingle{
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0,0,0.47); 
                box-shadow: 2px 4px 10px 1px  rgba(201, 201,201,0.47);
                width: 100%;
                height: 150px;
                background-color: #f3f3f3;
                border-radius: 40px;
                align-items: center;
                display: flex;
                flex-direction: row;
                padding: 10px;
    

                .reviewLeft{
                    width:40%;
                    height:130px;
                    margin-right: 20px;
                    display: flex;
                    align-items: center;
                    flex-direction: row;

                    .reviewPhoto{
                        display: flex;
                        gap: 20px;
                        padding-left: 20px;
                        width: 100px;
                        height: 80px;
                        border-radius: 50%;
                        


                        .itemimg{
                           
                            border-radius: 50%;
                            object-fit: contain;
                        }
                    }

                    .details{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .name{
                            color: darkgreen;
                            font-size: 15px;
                            font-weight: bold;
                            margin-bottom: 5px;
                        }

                        .email{
                            color:#383636;
                            font-size: 13px;
                            margin-bottom: 5px;

                        }

                        .telephone{
                            color:#7a7979;
                            size: 10px;
                            font-size: 12px;
                        }
                    }
                }

                .reviewRight{
                    width: 60%;
                    height: 150px;
                    display: flex;
                    flex-direction: column;

                    .reviewRightTop{
                        display: flex;
                        flex-direction: row;
                        margin-top: 10px;
                        margin-bottom: 20px;
                        align-items: center;
                        margin-bottom: 20px;

                        .reviewRightTopLeft{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            width: 520px;
                        }

                        .reviewRightTopRight{
                            margin-left: 50px;

                            .deleteReview{
                                cursor: pointer;
                                border-width: 0px;
                                // background-color:rgb(223, 247, 223);
                                border-radius: 5px;
                                padding: 5px;

                                &:hover{
                                    background-color:rgb(226, 235, 226);
                                }

                                p{
                                    color: green;
                                    font-size: 11px;


                                    &:hover{
                                        color: #7a7979;
                                       
                                    }
                                }
                            }

                        }



                        .ratingContainer{
                            display: flex;
                            flex-direction: row;
                            width:150px;
                            justify-content: space-between;
                            margin-right: 50px;

                            .rating{

                                font-size:18px;
                                font-weight:400;
                                margin-right: 5px;
                                color: goldenrod;
                            }
                               
                            
                        }

                        .transaction{
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .title{

                                font-size: 11px;
                                color: #575353;
                                margin-right: 10px;
                                font-weight: 600;
                            }
                            p{
                                font-size: 11px;
                                color: #383636;
                            }
                        }

                    }

                    .reviewMiddle{
                        height: 50px;

                        p{
                            font-size: 14px;
                            color: #383636;
                        }
                    }

                    .reviewBottom{
                        display:flex;
                        flex-direction:row;
                        align-items: center;
                        height: 10px;
                        // margin-top: 10px;

                        .reviewBottomMail{
                            display:flex;
                            flex-direction: row;
                            align-items: center;
                            padding:5px;
                            background-color:rgb(223, 247, 223);
                            border-radius: 5px;
                            margin-right: 100px;
                            border-width:0px;
                            cursor: pointer;

                            &:hover{
                                background-color:rgb(226, 235, 226);
                            }

                            .mail{
                                color:green;
                                font-size:12px;
                                font-weight:400;
                                margin-right: 5px;

                            }

                            p{
                                font-size: 10px;
                                font-weight: 400;
                                color: green;

                            }
                        }

                        .reply{
                            padding-left:5px;
                            padding-left:5px;
                            background-color:rgb(223, 247, 223);
                            border-radius: 5px;
                            border-width: 0px;
                            cursor: pointer;

                            &:hover{
                                background-color:rgb(226, 235, 226);
                            }

                            p{
                                font-size: 10px;
                                font-weight: 400;
                                color: green;
                            }
                        }
                    }
                }
            }
        }
    }
}

.spinner-container{
    display: grid;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
     }
      .loading-spinner {
        width: 50px;
        height: 50px;
        border: 10px solid #f3f3f3; /* Light grey */
        border-top: 10px solid #383636; /* Black */
        border-radius: 50%;
        animation: spinner 1.5s linear infinite;
      }

}