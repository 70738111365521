div{
.container{
    display:'flex'; 
    flex-direction:'column';
    gap:20;

    .rowWrapper{
        display:'flex';
        flex-direction:'row';
        gap:20
    }
}
}