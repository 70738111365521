.login{
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    legend{
        font-size: 32px;
        color: #020066;
        margin: 0 10px;
    }

    form{
        display: flex;
        flex-direction: column;
        align-items: center;
        width:500px;
        height: 300px;
        // background-color: lightgray;
        align-items: center;
        justify-content: center;
        

        .emailError{
            color: 'red';
            font-size: 10px;

        }

        .passwordError{
            color: 'red';
            font-size: 10px;
        }

        input{
            width: 200px;
            height: 30px;
            margin-top: 20px;
            font-size: 16px;
            padding: 10px;

            &:active{
                border: none;
                border-color: red;
            }
        }

        .forgotPwd{
            display: flex;
            font-size: 14px;
            color: rgb(107, 106, 106);
            margin-bottom: 10px;
            width: 220px;
            height:20px;
            justify-content: right;
        }

        button{
            width: 220px;
            height: 40px;
            margin: 10px;
            padding: 10px;
            color: white;
            background-color: #020066;
            border: none;
            cursor: pointer;
            font-size: 14px;
        }

        span {
            color: red;
        }
    }

}