.viewbutton{
    color: blue;
    margin-right: 10px;
    padding:3px 5px;
    border: 1px solid blue;
    border-radius: 5px;
    cursor: pointer;
}

.detailItem{

    // display: flex;
    flex-direction: row;
    gap:20px;

    .documentInfo{
        // display: flex;
        flex-direction: row;
        gap: 10px;
        
    }
}