.table{

   .tableHead {

        

    .tableRow{

        .tableCell {

            font-weight: bold;

        
        }

        
        .viewbutton{
            color: green;
            margin-right: 10px;
            padding:3px 5px;
            // border: 1px solid blue;
            border-radius: 5px;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            background-color: lightgreen;
        }
        

        .tableDate{

            width: 100px;

        }

    }

   }

//    .tableBody {

//     .tableRow{

//         .tableCell {
        
//             font-size: 14px;

//         }

//     }

//    }

    .cellWrapper{
        display: flex;
        align-items: center;

        .image{
            width: 60px;
            height: 60px;
            border-radius:50%;
            // object-fit: cover;
        }
    }
    .status{
        padding: 5px;
        border-radius: 5px;

        &.delivered{
            color: green;
            background-color: rgb(194, 245, 194);
        }
        &.canceled{
            color: goldenrod;
            background-color: rgb(236, 236, 236);
        }

    }
    
    
}